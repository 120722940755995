#root {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.header-banner {
    @include banner(url('../images/header.jpg'),right);
}

.commerce-banner {
    @include banner(url('../images/commerce.jpg'));
}

.crafts-banner {
    @include banner(url('../images/crafts.jpg'), left);
}

.logo-wrapper {
    @include flex();
    height: 100px;
    background: #fff;

    &--simple {
        @include flex(row, center, flex-start);
    }
}

.logo-container, .nav {
    display: flex;
    width: 100%;
    max-width: $max-width;

    &--simple {
        align-items: center;
        max-width: initial;
    }
}

.logo-container {
    img {
        height: 50px;
    }

    &--simple {
        a {
            padding: 0 24px;
        }

        img {
            margin-left: 16px;
        }
    }
}

.logo {
    &--simple {
        width: auto;
        height: 60px;
        max-height: 60px;
    }
}

.nav-container {
    @include flex();
    background: $primary-main;
    position: sticky;
    top: 0;
    z-index: 2;
}

.nav {
    a, button, p {
        display: inline-flex;
        align-items: center;
        padding: 0 24px;
        line-height: 60px;
        color: $primary-text;
        background: transparent;
        border: none;
        font-size: 16px;
        text-decoration: none;
        transition: .15s ease-in-out;

        &:disabled {
            pointer-events: none;
            opacity: .5;
        }

        svg {
            font-size: 22px;
        }

        b {
            margin-left: 4px;
        }

        &::before {
            line-height: initial;
        }
    }

    a, button {
        cursor: pointer;
    }

    .active {
        background: $primary-dark;
    }

    .language-switch-container {
        margin-left: auto;
    }

    &--simple {
        flex-wrap: wrap;

        a, button, p {
            font-size: 15px;
            text-transform: initial;
        }

        a:hover {
            background-color: $primary-light;
            color: $primary-dark;
        }

        .language-switch-container {
            margin-left: 0;
        }
    }
}

.nav:not(.nav--simple) {
    a, button {
        &:hover {
            background: $primary-light;
            color: $primary-dark;
        }
    }
}

.footer-logo-container {
    @include flex(row, flex-start, space-between);
    max-width: $max-width;

    img {
        display: block;
        height: 50px;
    }
}

.in-article-image {
    width: 100%;
    height: auto;
    margin-top: 25px;
}

.course-image-container {
    display: flex;
    margin: 25px 0 0 0;

    .course-image {
        height: 175px;
        width: auto;
        margin: 0;
    }
}

.main {
    @include flex();
    max-width: $max-width;
}

.progress-main {
    @include flex(column, flex-start);
    width: 100%;
    padding: 16px;
}

.score-block {
    max-width: 600px;
    padding: 48px 24px 24px;
    background: $gray-border;
    margin-left: 150px;

    img {
        position: absolute;
        left: 115%;
        top: 15%;
        height: 66%;
        width: auto;
    }
}

.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.7;
}

#score-value {
    font-weight: bold;
    margin-right: auto;
}

.form-field {
    @include flex(row, center, flex-start);
}

.footer-popup-container {
    @include flex(column, flex-start);
    padding: 16px;
}

.footer-popup-buttons {
    @include flex();
    width: 100%;
    margin-top: 30px;

    button:first-child {
        margin-right: 15px;
    }
}

.start-course-container {
    @include flex();
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    max-width: 650px;
}

.courses-container {
    display: flex;
    justify-content: space-between;
}


.course {
    @include noticeable;
    width: 47.5%;
    display: flex;
    flex-direction: column;

    &__button {
        width: fit-content;
    }
}

.spacer {
    flex-grow: 1;
}

.go-to-course-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.breadcrumbs {
    padding: 0 24px;

    a {
        font-size: 14px;
        color: $typography-color !important;
        text-decoration: none;
        text-transform: uppercase;
    }
}

.page-wrapper {
    flex-grow: 1;
}

div.timer {
    font-size: 20px;
    font-weight: bold;
    margin-top:-15px;
    margin-bottom: 5px;
}