a[disabled] {
    pointer-events: none;

    &:not(.router-link) {
        filter: brightness(0.5);
    }
}

li {
    line-height: 24px;
}

ul, ol {
    margin-left: 17.5px;
}

.error-page {
    @include flex();
}

.user-page {
    @include flex(column);
    padding: 16px;

    section {
        width: 100%;
        max-height: calc(42px);
        border-bottom: 1px solid $secondary-text;
        margin-bottom: 16px;
        padding-left: 16px;
        overflow: hidden;
        color: $secondary-text;
        transition: .15s ease-in-out;
        cursor: pointer;

        &.active {
            max-height: 1000px;

            &::before {
            transform: rotate(90deg);
            }
        }

        &.disabled {
            pointer-events: none;
        }

        &::before {
            content: "";
            position: absolute;
            top: 13px;
            left: 2px;
            width: 0;
            height: 0;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-left: 8px solid $secondary-text;
        }

        .title {
            font-size: 19px;
            line-height: 42px;
            color: $secondary-text;
        }

        .content {
            padding: 0 0 16px 16px;
            display: flex;
            flex-direction: column;

            & > div {
                margin-bottom: 16px;
            }

            p {
                font-size: 13px;
                width: 300px;
                text-align: right;
                padding-right: 48px;
            }

            input {
                min-width: 300px;
                border: none;
                border-bottom: 2px solid $secondary-text;
                padding: 8px;
            }
        }
    }
}

footer {
    width: 100%;
    background: $primary-main;
    padding: 32px;
    margin-top: 64px;
    color: $gray-border;

    &.simple {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100px;
        border-top: 2px solid $gray-border;
        padding: 16px;
        color: $gray-background;
        background: initial;

        & > *:not(:last-child) {
            margin-right: 16px;
        }

        & > *:first-child {
            margin-right: auto;
        }
    }
}

.versions-container {
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
}